export default function (seo) {
  const head = { link: [], meta: [], script: [] }

  if (seo.canonicalUrl) {
    head.link.push({ rel: 'canonical', href: seo.canonicalUrl })
  }

  if (seo.metaTitle) {
    head.meta.push({ name: 'og:title', content: seo.metaTitle })
    useTitle(seo.metaTitle)
  }

  if (seo.metaDescription) {
    head.meta.push({ name: 'description', content: seo.metaDescription })
    head.meta.push({ name: 'og:description', content: seo.metaDescription })
  }

  if (seo.metaImage) {
    head.meta.push({ name: 'twitter:card', content: 'summary_large_image' })
    head.meta.push({
      name: 'og:image',
      content: useStrapiMedia(seo.metaImage.url)
    })
    head.meta.push({
      name: 'image',
      property: 'og:image',
      content: useStrapiMedia(seo.metaImage.url)
    })
  }

  if (seo.metaRobots) {
    head.meta.push({ name: 'robots', content: seo.metaRobots })
  }

  if (seo.structuredData) {
    head.script.push({ children: JSON.stringify(seo.structuredData), type: 'application/ld+json' })
  }

  useHead(head)
}
